import React from 'react';
import './styles/Logo.css'

function Logo() {
    return(
        <div className="logo-red">
            <div className="logo-white"></div>
        </div>
    )
}

export default Logo;