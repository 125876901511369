import React from 'react'
import './styles/Arrow.css';
function UpArrow(){
    return(
        <div className="arrow">
              <div className="arrow-head"></div>
              <div className="arrow-body"></div>
        </div>
      
    )
}
export default UpArrow;